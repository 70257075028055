import logo from './assets/happr_icon.png'
function App() {
  return (
    <div className="container mx-auto h-screen bg-white overflow-auto">
      <div className="h-screen self-center flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="w-80 h-20 object-contain mb-10" />
        <h1 className="text-3xl font-bold">Welcome to happr.ai</h1>
      </div>
    </div>
  )
}

export default App
